import { parseISO } from 'date-fns';

const dateRegex = new RegExp(
  '(\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d\\.\\d+([+-][0-2]\\d:[0-5]\\d|Z))|(\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d([+-][0-2]\\d:[0-5]\\d|Z))|(\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d([+-][0-2]\\d:[0-5]\\d|Z))',
);

export function convertIsoStringsToDateObjects<T>(
  object: T,
  parent?: Record<string, unknown> | unknown[],
  key?: number | string,
) {
  if (object === null) return;

  if (typeof object === 'string') {
    if (dateRegex.test(object)) {
      // @ts-ignore
      parent[key] = parseISO(object);
    }
  } else if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) convertIsoStringsToDateObjects(object[i], object, i);
  } else {
    for (const key of Object.keys(object as Record<string, unknown>)) {
      convertIsoStringsToDateObjects((object as Record<string, unknown>)[key], object as Record<string, unknown>, key);
    }
  }

  return object;
}
